<template>
  <div v-if="Object.keys(transaction).length !== 0">
    <div class="">
      <b-modal id="request-transaction-status-modal" title="Request Transaction Status" size="xl" hide-footer>
        <div class="modal-content bg-white rounded">
          <div class="card">
            <div class="card-body">
              <a-steps :current="current" size="small" status="wait">
                <a-step title="Hub Requested" :description="transaction.requested_transaction_statuses.filter(e => e.status === 'HUB_REQUESTED').length > 0 ? customDate(transaction.requested_transaction_statuses.find(e => e.status === 'HUB_REQUESTED').created_at) : ''" />
                <a-step title="Admin Approved" v-if="transaction.requested_transaction_status.includes('ADMIN_APPROVED') && !transaction.requested_transaction_status.includes('ADMIN_REJECTED')"  :description="transaction.requested_transaction_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0 ? customDate(transaction.requested_transaction_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) : ''" />
                <a-step class="ant-steps-item-error" title="Admin Rejected" v-else-if="transaction.requested_transaction_status.includes('ADMIN_REJECTED') && !transaction.requested_transaction_status.includes('ADMIN_APPROVED')"  :description="transaction.requested_transaction_statuses.filter(e => e.status === 'ADMIN_REJECTED').length > 0 ? customDate(transaction.requested_transaction_statuses.find(e => e.status === 'ADMIN_REJECTED').created_at) : ''" >
                    <a-icon slot="icon" type="close-circle" />
                </a-step>
                <a-step title="Admin Pending" v-else></a-step>
              </a-steps>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'status',
  props: ['current', 'transaction'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LLL')
    },
  },
}
</script>

<style scoped>
.ant-steps-item-description {
  margin-top: -5px !important;
}
</style>
